// 售后状态
// 01: '创建申请'
// 02: '商铺同意'
// 03: '商铺拒绝'
// 04: '用户发货'
// 05: '供应商收货'
// 06: '供应商拒收'

const formatMap = arr => {
  let map = {};
  arr.forEach(e => map[e.id] = e.name);
  return map;
};

// 维权售后退换货状态
export const CHUANG_JIAN_SHEN_QING = '01';
export const SHANG_PU_TONG_YI = '02';
export const SHANG_PU_JU_JUE = '03';
export const YONG_HU_FA_HUO = '04';
export const GONG_YING_SHANG_SHOU_HUO = '05';
export const GONG_YING_SHANG_JU_SHOU = '06';
export const DIAN_PU_JU_JUE_GONG_YING_SHANG_TONG_YI = '08';
export const DIAN_PU_JU_JUE_GONG_YING_SHANG_JU_JUE = '09';
export const RETURN_STATUS = [
  { id: CHUANG_JIAN_SHEN_QING, name: '创建申请' },
  { id: SHANG_PU_TONG_YI, name: '商铺同意' },
  { id: SHANG_PU_JU_JUE, name: '商铺拒绝' },
  { id: YONG_HU_FA_HUO, name: '用户发货' },
  { id: GONG_YING_SHANG_SHOU_HUO, name: '供应商收货' },
  { id: GONG_YING_SHANG_JU_SHOU, name: '供应商拒收' },
  { id: DIAN_PU_JU_JUE_GONG_YING_SHANG_TONG_YI, name: ' 店铺拒绝供应商同意' },
  { id: DIAN_PU_JU_JUE_GONG_YING_SHANG_JU_JUE, name: ' 店铺拒绝供应商拒绝' },
];
export const RETURN_STATUS_MAP = formatMap(RETURN_STATUS);
